@use "../util" as *;

.hero {
  @include flexCenter(column);
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
  background-color: $clr-primary;
  .hero-center {
    display: flex;
    align-items: center;
    width: $pageWidth;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  .product-details {
    flex: 1;
    margin-bottom: 6rem;
    z-index: 2;
  }
  .product-img {
    flex: 1 20%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .title {
    font-size: $heading-xlarge;
    text-transform: uppercase;
    color: white;
    margin: 2rem 0;
  }

  .desc {
    font-size: $text;
    margin-bottom: 2.5rem;
    color: $clr-text-light;
  }

  .product-btn a {
    @include productButton(white, $clr-accent, $clr-accent);
  }
}

@media (max-width: $bp-laptop) {
  .hero-center {
    justify-content: center;
    .product-details {
      flex: 0 55%;
      text-align: center;
      button {
        margin: auto;
      }
    }
    .product-img {
      position: absolute;
      img {
        padding-top: 5rem;
        transform: scale(1.25);
      }
    }
  }
}

@media (max-width: $bp-tablet) {
  .hero-center {
    .product-img {
      img {
        transform: scale(1.54);
      }
    }
  }
}

@media (max-width: $bp-phone) {
  .hero-center {
    .title {
      font-size: 12vw;
    }
    .desc {
      font-size: 4.5vw;
    }
  }
}
