@use "../util" as *;

.single-product {
  .product {
    align-items: flex-start;
    .details {
      order: 2;
      align-items: flex-start;
      .tag {
        display: flex;
        color: $clr-accent;
        margin-bottom: 1rem;
      }
      .name {
        text-align: left;
      }
      .desc {
        text-align: left;
      }
      .price {
        font-size: $text;
        color: $clr-primary;
        font-weight: 800;
      }
      .user-input {
        display: flex;
        margin-top: 2rem;
        .amount-btn-wrapper {
          display: flex;
          max-width: max-content;
          margin-right: 1rem;
          position: relative;
        }
        .change-amount-btn {
          flex: 1;
          height: 100%;
          width: 40%;
          justify-content: center;
          align-items: center;
          position: absolute;
          @include transition(color);
          &:last-of-type {
            right: 0;
          }
          &:hover {
            color: $clr-accent;
          }
        }
        input {
          border: none;
          font-size: $text;
          background-color: rgb(0, 0, 0, 0.1);
          text-align: center;
          outline: none;
          width: 100%;
          height: 100%;
          max-width: 7rem;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            appearance: none;
            margin: 0;
          }
        }
        .product-btn {
          @include productButton(white, $clr-accent, $clr-accent);
        }
      }
    }
    .img-wrapper {
      order: 1;
      max-height: 600px;
    }
    & + .info {
      display: flex;
      gap: 8rem;
      h2 {
        font-size: $heading-medium;
        text-transform: uppercase;
        color: $clr-primary;
        margin-bottom: 2.5rem;
      }
      .features {
        flex: 3;
        p {
          margin-bottom: 2rem;
          line-height: 170%;
          &:last-of-type {
            margin: 0;
          }
        }
      }
      .box-contents {
        flex: 1;
        ul {
          font-size: $text;
          list-style-type: none;
          li {
            text-transform: capitalize;
            margin-bottom: 1.5rem;
            display: flex;
            span {
              color: $clr-accent;
              margin-right: 1rem;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  .gallery {
    display: grid;
    grid-template-columns: 0.75fr 1fr;
    gap: 2rem;
    div {
      height: 20rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $radius;
        filter: saturate(0) brightness(1.25);
      }
      &.picture-bg {
        grid-row: 1 / 3;
        grid-column: 2 / 3;
        height: 42rem;
      }
    }
  }
}

@media (max-width: $bp-desktop) {
  .single-product {
    .product + .info {
      gap: 2rem;
    }
  }
}

@media (max-width: $bp-laptop) {
  .single-product {
    .product {
      & + .info {
        flex-direction: column;
        gap: 4rem;
        .box-contents {
          display: flex;
          gap: 12.5rem;
        }
      }
    }
    .gallery {
      div {
        height: 15rem;
        &.picture-bg {
          height: 32rem;
        }
      }
    }
  }
}

@media (max-width: $bp-tablet) {
  .single-product {
    .product {
      & + .info {
        flex-direction: column;
        gap: 4rem;
        .box-contents {
          flex-direction: column;
          gap: 0;
        }
      }
    }
    .gallery {
      grid-template-columns: auto;
      div {
        height: 20rem;
        &.picture-bg {
          grid-row: unset;
          grid-column: unset;
          height: 20rem;
        }
      }
    }
  }
}

@media (max-width: $bp-phone) {
  .single-product {
    .product {
      & + .info {
        h2 {
          font-size: 9vw;
        }
        p,
        ul li {
          font-size: 4.5vw;
        }
      }
    }
  }
}
