@use "../util" as *;

.modal-bcg {
  @include flexCenter(column);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.7);
  z-index: -10;
  visibility: hidden;
  opacity: 0;
  @include transition(opacity);
  &.show {
    z-index: 10;
    visibility: visible;
    opacity: 1;
  }
}

.modal {
  width: clamp(280px, 90%, 600px);
  background-color: white;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;
  border-radius: $radius;
  @include transition(opacity);
  .icon {
    color: white;
    font-size: $heading-medium;
    display: flex;
    background-color: $clr-accent;
    padding: 1.5rem;
    border-radius: 50%;
  }
  h2 {
    font-size: $heading-large;
    color: black;
    text-transform: uppercase;
  }
  p {
    font-size: $text;
  }
  .cards {
    display: flex;
    width: 100%;
    border-radius: $radius;
    overflow: hidden;
    .order {
      flex: 3;
      background-color: rgb(0, 0, 0, 0.1);
      .item {
        display: flex;
        padding: 2rem;
        gap: 2rem;
        .img-wrapper {
          min-width: 60px;
          width: 60px;
          height: 60px;
          border-radius: $radius;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .details {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .name {
            color: black;
            text-transform: uppercase;
            font-size: $text;
          }
          .price {
            font-size: $text;
          }
        }
        .quantity {
          font-size: $text;
          margin-top: 0;
          margin-bottom: auto;
          display: flex;
          line-height: 100%;
        }
      }
      .other-items {
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
      }
    }
    .order-total {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: black;
      text-transform: uppercase;
      font-size: $text;
      padding: 2rem;
      gap: 1rem;
      span {
        color: white;
        font-weight: 700;
      }
    }
  }
  .home-btn {
    @include productButton(white, $clr-accent, $clr-accent);
    width: 100%;
    text-align: center;
  }
}

@media (max-width: $bp-phone) {
  .modal {
    padding: 1.5rem;
    gap: 1rem;
    .icon {
      font-size: $heading-small;
      padding: 1rem;
    }
    h2 {
      font-size: 8.25vw;
    }
    p {
      font-size: 4.5vw;
    }
    .cards {
      flex-direction: column;
      .order,
      .order-total {
        justify-content: center;
        align-items: center;
      }
      .order-total {
        padding: 1rem;
      }
      .order .item {
        justify-content: center;
        gap: 1rem;
        padding: 1rem;
        .img-wrapper {
          min-width: 50px;
          width: 50px;
          height: 50px;
        }
        .details {
          .name,
          .price,
          .quantity {
            font-size: 4vw;
          }
        }
        .quantity {
          font-size: 4vw;
        }
      }
      .other-items {
        margin-bottom: 1rem !important;
      }
    }
  }
}
