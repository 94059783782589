@use "../util" as *;

.summary {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border: 1px solid rgb(180, 180, 180);
  border-radius: $radius;
  gap: 2rem;
  height: max-content;
  max-width: 40%;
  h2 {
    display: flex;
    color: $clr-primary;
    font-size: $heading-small;
    text-transform: uppercase;
  }
  .items {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 0.5rem 0;
  }

  .item {
    display: flex;
    .img-wrapper {
      width: 60px;
      height: 60px;
      border-radius: $radius;
      overflow: hidden;
      background-color: rgb(0, 0, 0, 0.1);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $radius;
      }
    }
    .details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0 1rem;
      width: 12.5rem;
      .name {
        font-size: $text-small;
        color: $clr-primary;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .price {
        margin: 0.25rem 0;
        letter-spacing: 1px;
      }
    }

    .quantity,
    .price {
      font-size: $text-small;
      font-weight: 600;
      line-height: 100%;
    }
    .quantity {
      height: 100%;
    }
  }
  .expenses {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .grand-total,
  .expense {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $text;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 100%;
    span {
      color: $clr-primary;
      font-weight: 700;
      font-size: $heading-small;
      letter-spacing: 1px;
    }
  }
  .grand-total {
    line-height: 100%;
    span {
      color: $clr-accent;
    }
  }
  .confirm-purchase-btn {
    @include productButton(white, $clr-accent, $clr-accent);
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: $bp-desktop) {
  .summary {
    max-width: 100%;
    padding: 2rem 3rem;
    .items {
      gap: 3rem;
    }
    .item {
      .img-wrapper {
        width: 150px;
        height: 150px;
      }
      .details {
        margin: 0 2rem;
        .name,
        .price {
          font-size: $heading-small;
        }
      }
      .quantity {
        font-size: $heading-small;
        margin-right: 0;
        margin-left: auto;
      }
    }
    .expenses {
      gap: 1.5rem;
    }
  }
}

@media (max-width: $bp-tablet) {
  .summary {
    padding: 2rem 3rem;
    .item {
      .img-wrapper {
        width: 100px;
        height: 100px;
      }
      .details {
        .name,
        .price {
          font-size: $heading-small;
        }
      }
      .quantity {
        font-size: $heading-small;
      }
    }
  }
}

@media (max-width: $bp-phone) {
  .summary {
    max-width: 100%;
    padding: 2rem 1.5rem;
    .items {
      gap: 2rem;
    }
    .item {
      gap: 1rem;
      .img-wrapper {
        min-width: 75px;
        width: 75px;
        height: 75px;
      }
      .details {
        margin: 0;
        .name,
        .price {
          font-size: 5vw;
        }
      }
      .quantity {
        font-size: 5vw;
      }
    }
  }
}
