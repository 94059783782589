@use "../util" as *;

.checkout {
  flex-direction: row;
  gap: 2rem;
  & > h2 {
    text-align: center;
  }
}

.form-wrapper {
  width: 100%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  border: 1px solid rgb(180, 180, 180);
  border-radius: $radius;
}

.form-checkout {
  max-width: 70%;
  flex: 3;
  input {
    border: 1px solid rgb(180, 180, 180);
    outline: none;
    font-size: $text-small;
    color: $clr-text;
    padding: 1rem 1.5rem;
    border-radius: $radius-small;
    transition: box-shadow 0.3s ease, border 0.3s ease;
    &:focus {
      border: 1px solid $clr-accent;
      box-shadow: 0 0 6px $clr-accent;
    }
  }

  .user-message + input {
    border: 1px solid $clr-red-light;
    box-shadow: 0 0 6px $clr-red-light;
  }
  h1 {
    font-size: $heading-medium;
    text-transform: uppercase;
    color: $clr-primary;
  }

  h3 {
    font-size: $heading-xsmall;
    text-transform: uppercase;
    color: $clr-accent;
    margin-bottom: 1.5rem;
  }

  label {
    font-size: $text;
    text-transform: capitalize;
    font-weight: 600;
    color: $clr-primary;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 0.5rem;
    input {
      margin-top: 0.5rem;
    }
  }

  .billing-details,
  .shipping-info,
  .payment-details {
    .input-fields {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  }

  .shipping-info .input-fields {
    // address input field
    label:first-of-type {
      grid-column: 1 / 3;
    }
  }
  .payment-details .input-fields {
    // payment method
    & > label:first-of-type {
      display: flex;
      flex-direction: row;
      grid-column: 1 / 3;
      gap: 1.5rem;
      & > span {
        flex: 1;
      }
      .input-radios {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        label {
          border: 1px solid rgb(180, 180, 180);
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 1rem 1.5rem;
          border-radius: $radius;

          span::first-letter {
            text-transform: lowercase;
          }
          input {
            transform: scale(1);
            margin: 0;
            margin-right: 1rem;
            width: initial;
            &:focus {
              box-shadow: none;
              border: initial;
            }
          }
        }
      }
    }
  }
  .delivery-info {
    grid-column: 1 / 3;
    display: flex;
    align-items: center;
    .icon {
      font-size: $heading-large;
      margin: 0 2rem;
    }
    p {
      font-size: $text-small;
    }
  }
}

@media (max-width: $bp-desktop) {
  .checkout {
    flex-direction: column;
  }
  .form-checkout {
    max-width: 100%;
    flex: 1;
  }
}

@media (max-width: $bp-laptop) {
  .form-checkout {
    .billing-details,
    .shipping-info,
    .payment-details {
      .input-fields {
        grid-template-columns: 1fr;
        gap: 1rem;
      }
    }
    .shipping-info .input-fields {
      // address input field
      label:first-of-type {
        grid-column: unset;
      }
    }

    .payment-details .input-fields {
      // payment method
      & > label:first-of-type {
        flex-direction: column;
        grid-column: unset;
      }
    }

    .delivery-info {
      grid-column: unset;
    }
  }
}

@media (max-width: $bp-phone) {
  .form-wrapper {
    padding: 1.5rem;
    h1 {
      font-size: 9vw;
    }
    h3 {
      font-size: 5vw;
    }
    input {
      font-size: 4vw;
      padding: 1em;
    }
    label {
      font-size: 4.5vw;
    }
    .delivery-info {
      flex-direction: column;
      p {
        font-size: 4.5vw;
      }
    }
  }
}
