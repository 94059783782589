@use "../util" as *;

.product {
  display: flex;
  align-items: center;
  gap: 5rem;
  height: 500px;
  .img-wrapper {
    flex: 1;
    width: 50%;
    height: 100%;
    border-radius: $radius;
    overflow: hidden;
    background-color: rgb(0, 0, 0, 0.1);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .details {
    flex: 1;
    .tag {
      color: $clr-accent;
    }
    .name {
      font-size: $heading-large;
      text-transform: uppercase;
      color: $clr-primary;
      margin-top: 1rem;
    }
    .desc {
      line-height: 170%;
      margin: 2rem 0;
    }
    .product-btn a {
      @include productButton(white, $clr-accent, $clr-accent);
    }
  }
  // picture to right and details to left
  @media (min-width: $bp-laptop) {
    &:nth-child(2n) {
      .details {
        order: 1;
      }
      .img-wrapper {
        order: 2;
      }
    }
  }
}

@media (max-width: $bp-laptop) {
  .product {
    height: max-content;
    flex-direction: column;
    gap: 4rem;
    .img-wrapper {
      flex: unset;
      width: 100%;
      height: 600px;
      max-height: 600px;
    }
    .details {
      @include flexCenter(column);
      flex: unset;
      .name {
        text-align: center;
      }
      .desc {
        max-width: 50ch;
        text-align: center;
      }
    }
  }
}

@media (max-width: $bp-tablet) {
  .product {
    .img-wrapper {
      height: 400px;
      max-height: 400px;
    }
  }
}

@media (max-width: $bp-phone) {
  .product {
    gap: 2rem;
    .img-wrapper {
      height: 300px;
      max-height: 300px;
    }
    .details {
      .tag {
        font-size: 4vw;
      }
      .name {
        font-size: 10vw;
      }
      .desc {
        font-size: 4.5vw;
        margin: 1.5rem 0;
      }
    }
  }
}
