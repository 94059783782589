@use "../util" as *;

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Manrope", sans-serif;
}

body {
  display: flex;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  color: $clr-text;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  line-height: 100%;
}

button {
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
}

#root {
  @include flexCenter(column);
  width: 100%;
  min-height: 100vh;
  color: $clr-text;
}

main {
  display: flex;
  flex-direction: column;
  width: $pageWidth;
  gap: 12rem;
  margin: 12rem 0;
  position: relative;
}

p {
  font-size: $text;
}

.company-name {
  color: white;
  font-size: $heading-medium;
  font-weight: 700;
  z-index: 2;
}

.tag {
  text-transform: uppercase;
  letter-spacing: 7px;
  font-weight: 500;
  font-size: 1.25rem;
}

.go-back-btn {
  position: absolute;
  transform: translateY(-5rem);
  text-transform: capitalize;
  font-size: $text;
  color: $clr-text;
  @include transition(opacity);
  &:hover {
    opacity: 0.7;
  }
}

.bcg-blur {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.6);
  z-index: -5;
  visibility: hidden;
  opacity: 0;
  @include transition(opacity);
}

.user-message {
  background-color: $clr-red-light;
  color: $clr-red-dark;
  border-radius: $radius-small;
  max-width: max-content;
  font-size: 0.9rem;
  padding: 0.1rem 0.3rem;
  position: absolute;
  bottom: 0;
  left: 0.25rem;
  transform: translateY(calc(100% + 0.2rem));
  font-weight: 600;
  animation: messageAppear 0.3s forwards;
  .notch {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;

    border-bottom: 8px solid $clr-red-light;
    position: absolute;
    top: 0;
    left: 0.5rem;
    transform: translateY(-0.25rem);
  }
}

@keyframes messageAppear {
  from {
    z-index: -10;
    visibility: hidden;
    opacity: 0;
  }
  to {
    z-index: 5;
    visibility: visible;
    opacity: 1;
  }
}

@media (max-width: $bp-phone) {
  .user-message {
    font-size: 3vw;
  }
}

// accessibility
.visually-hidden {
  clip: rect(0 0 0 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
}

// hide arrows in number inputs
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}

// custom radio buttons, works except on firefox
input[type="radio"] {
  accent-color: $clr-accent;
  transform: scale(1.5) !important;
}
