@mixin flexCenter($direction) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $direction;
}

@mixin productButton($color, $bgc, $brd-clr) {
  padding: 0.75rem 1.75rem;
  text-transform: uppercase;
  color: $color;
  background-color: $bgc;
  border: 2px solid $brd-clr;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 0.85rem;
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 0.7;
  }
}

@mixin transition($type) {
  transition: $type 0.3s ease;
}
