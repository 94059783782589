@use "../util" as *;

.cart-wrapper {
  position: absolute;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
}

.cart {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: clamp(280px, 100%, 500px);
  top: 7rem;
  right: 0;
  background-color: white;
  padding: 2rem;
  border-radius: $radius;
  z-index: -5;
  visibility: hidden;
  opacity: 0;
  @include transition(opacity);
  & > h2 {
    text-align: center;
    text-transform: capitalize;
  }
  h4 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    span {
      color: $clr-primary;
      font-size: $heading-small;
      text-transform: uppercase;
    }
    .remove-all-btn {
      padding: 0.5rem;
      padding-right: 0;
      padding-top: 0;
      font-size: $text-xsmall;
    }
  }
  .items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 30vh;
    overflow-y: auto;
  }
  .item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 1rem;
    position: relative;
    .img-wrapper {
      min-width: 75px;
      width: 75px;
      height: 75px;
      border-radius: $radius;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .details {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;
      margin-right: auto;
      .name {
        font-size: $heading-xsmall;
        color: $clr-primary;
        text-transform: uppercase;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
      .price {
        font-size: $text-small;
        margin: 0.25rem 0;
        letter-spacing: 1px;
      }
      .remove-item-btn {
        font-size: $text-xsmall;
      }
    }
    .quantity-btns {
      display: flex;
      flex-direction: column;
      button,
      .quantity {
        flex: 1;
        @include flexCenter(row);
      }
      button {
        font-size: $text;

        @include transition(color);
        &:hover {
          color: $clr-accent;
        }
      }
      .quantity {
        font-size: $text-small;
      }
    }
  }
  .total {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $text;
    font-weight: 500;
    span {
      color: $clr-primary;
      font-weight: 700;
      font-size: $heading-small;
      letter-spacing: 1px;
    }
  }
  .checkout-btn a {
    @include productButton(white, $clr-accent, $clr-accent);
    width: 100%;
  }

  .remove-all-btn,
  .remove-item-btn {
    color: $clr-text;
    @include transition(opacity);
    &:hover {
      opacity: 0.7;
    }
  }
}

.cart-btn {
  position: relative;
  font-size: $heading-medium;
  padding: 1rem;
  padding-right: 0;

  color: white;
  @include transition(color);
  &:hover {
    color: $clr-accent;
  }
  .cart-amount-icon {
    @include flexCenter(row);
    position: absolute;
    left: 0.25rem;
    top: 0.25rem;
    background-color: $clr-accent;
    font-size: $text-small;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    color: white;
  }
}

header {
  &.cart-open {
    .cart-wrapper {
      z-index: 15;
    }
    .cart {
      z-index: 5;
      visibility: visible;
      opacity: 1;
    }
    .bcg-blur {
      z-index: 10;
      visibility: visible;
      opacity: 1;
    }
  }
}

@media (max-width: $bp-phone) {
  .cart {
    padding: 1.5rem 1rem;
    top: 6rem;
    h4 {
      margin-bottom: 0.5rem;
      span {
        font-size: $text;
      }
    }
    .items {
      max-height: 45vh;
    }
    .item {
      .img-wrapper {
        min-width: 50px;
        width: 50px;
        height: 50px;
        position: absolute;
      }
      .details {
        margin: 0;
        margin-top: 3.5rem;
        .name {
          font-size: $text-xsmall;
        }
        .price {
          font-size: $text-xsmall;
        }
      }
      .quantity-btns {
        flex-direction: row;
        position: absolute;
        right: 0;
        button {
          padding: 0.5rem 1rem;
        }
        button:first-of-type {
          order: 3;
          transform: rotate(90deg);
        }
        button:last-of-type {
          order: 1;
          transform: rotate(90deg);
        }
        .quantity {
          order: 2;
        }
      }
    }
    .total {
      margin: 1rem 0;
      font-size: $text-small;
      span {
        font-size: $text;
      }
    }
  }
}
