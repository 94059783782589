@use "../util" as *;

.recommended {
  h2,
  .name {
    text-align: center;
    font-size: $heading-medium;
    color: $clr-primary;
    text-transform: uppercase;
    margin-bottom: 4rem;
  }
}
.recommended-items {
  display: flex;
  gap: 2rem;

  .item {
    flex: 1;
    @include flexCenter(column);
  }
  .img-wrapper {
    width: 100%;
    height: 300px;
    background-color: rgb(0, 0, 0, 0.1);
    border-radius: $radius;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $radius;
    }
  }
  .name {
    font-size: $heading-small;
    margin: 2rem 0;
  }
  .product-btn a {
    @include productButton(white, $clr-accent, $clr-accent);
  }
}

@media (max-width: $bp-laptop) {
  .recommended-items {
    .img-wrapper {
      height: 250px;
      img {
        padding: 0;
      }
    }
  }
}

@media (max-width: $bp-tablet) {
  .recommended-items {
    flex-direction: column;
    gap: 5rem;
    .img-wrapper {
      height: 17.5rem;
    }
  }
}

@media (max-width: $bp-phone) {
  .recommended {
    h2 {
      font-size: 9vw;
    }
    .recommended-items {
      gap: 4rem;
    }
  }
}
