@use "../util" as *;

.categories {
  display: flex;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  min-height: max-content;
  margin-top: 6rem;
  a {
    color: inherit;
    flex: 1;

    :hover {
      .icon {
        transform: translateX(0.5rem);
      }
    }
  }
  .category {
    text-align: center;
    text-transform: uppercase;
    background-color: rgb(0, 0, 0, 0.1);
    position: relative;
    padding: 1.5rem 0;
    padding-top: 8rem;
    border-radius: $radius;
    div:last-of-type {
      @include flexCenter(row);
      font-weight: 600;
      .icon {
        font-size: $text;
        display: flex;
        color: $clr-accent;
        margin-left: 0.25rem;
        @include transition(transform);
      }
    }
  }
  .img-wrapper {
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .name {
    color: $clr-primary;
    margin: 1rem 0;
  }
  .float-shadow {
    display: flex;
    margin: 0 auto;
    position: relative;
    top: -2.5rem;
    width: 8rem;
    height: 0.25rem;
    box-shadow: 0 0 20px 10px black;
    filter: blur(12px);
    border-radius: 50%;
  }
}

@media (max-width: $bp-tablet) {
  .categories {
    flex-direction: column;
    gap: 7rem;
  }
}
