@use "../util" as *;

.about {
  display: flex;
  max-height: 650px;
  .details {
    flex: 1;
    margin: auto 0;
    padding-right: 7rem;
    max-width: 50%;
    h2 {
      font-size: $heading-large;
      text-transform: uppercase;
      color: $clr-primary;
      margin-bottom: 2rem;
      span {
        color: $clr-accent;
      }
    }
    p {
      line-height: 160%;
    }
  }
  .img-wrapper {
    flex: 1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $radius;
      filter: saturate(0) brightness(1.1);
    }
  }
}

@media (max-width: $bp-laptop) {
  .about {
    flex-direction: column;
    gap: 4rem;
    max-height: none;
    .details {
      order: 2;
      max-width: 100%;
      text-align: center;
      padding: 0;
    }
    .img-wrapper {
      order: 1;
      max-height: 40vh;
    }
  }
}

@media (max-width: $bp-phone) {
  .about {
    gap: 3rem;
    .details {
      h2 {
        font-size: 8vw;
      }
      p {
        font-size: 5vw;
      }
    }
  }
}
